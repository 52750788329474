import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
    getUser();
}, [fetchData, getUser]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 leftContainer" style={{ minHeight: "100vh" }}>
          <div className="cxy flex-column" style={{ paddingTop: "16%" }}>
             <img
              src="https://i.postimg.cc/3RvPdcH0/support-gif.gif"
              width="240px"
              alt=""
            />
            <div
              className="games-section-title mt-4"
              style={{
                fontSize: "1.0em",
                fontWeight: "500",
                color: "#2c2c2c",
                padding: "10px",
                border: "1px solid #000000",
                borderRadius: "8px",
                textAlign: "center",
                textTransform: "uppercase",
                letterSpacing: "2px",
              }}
             >
              <div className="card-header text-center" style={{ fontWeight: 500, fontSize: '15px', letterSpacing: '0.3px', position: 'relative', zIndex: '2' }}><b>Contacts us our platforms below</b></div>
               <p>(Monday to Saturday)</p> 
            <span className="font-9" style={{ color: 'red' }}> Support timing is from 11:00AM to 8:00PM</span>
             </div>
           </div>
            <div className="card mt-3" style={{ 
    border: '1px solid black', 
    width: '98%', 
    margin: '0 auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
    position: 'relative', // Added position property
    zIndex: '2' // Ensuring a higher z-index value
}}>
    <div className="card-header text-center" style={{ fontWeight: 500, fontSize: '15px', letterSpacing: '0.3px', position: 'relative', zIndex: '2' }}>
  <div className="row">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <div className="row md4">
  <div className="col-md-6 mb-4">
    <a href="https://t.me/Liveludo1234" target="_blank" rel="noreferrer">
      <button className="btn btn-primary support-button btn-3d text-white">
        <img
          width="25px"
          src="https://i.postimg.cc/02qsw8Zn/Telegram.png"
          alt=""
        />
        <span className="footer-text-white ml-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Telegram &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
  </button>
    </a>
  </div>
</div>
             
            <div className="col-md-6 mb-4">
    <a className="cxy flex-column" href="https://instagram.com/dausa5999" target="_blank">
      <button className="btn btn-danger support-button btn-3d text-white">
        <img
          width="25px"
          src="https://i.postimg.cc/Y95w8k7p/instagram.png"
          alt=""
        />
        <span className="footer-text-white ml-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Instagram &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </button>
    </a>
  </div>
 
 
              <div className="col-md-6 mb-4">
    <a className="cxy flex-column" href={`https://wa.me/9887249705?text=hello%20admin%20i%20need%20help%20%3F%20phone=${user?.Phone}`} target="_blank">
      <button className="btn btn-success support-button btn-3d text-white">
        <img
          width="25px"
          src="https://i.postimg.cc/VkfPKDxb/whatsapp.png"
          alt=""
        />
        <span className="footer-text-white ml-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; WhatsApp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
      </button>
    </a>
  </div>
</div> 
   
                       <div className="col-md-6 mb-4">
                <a className="cxy flex-column" href={`mailto:${WebSitesettings.CompanyEmail}`}>
                  <button className="btn btn-warning support-button btn-3d text-white">
                 <img
                      width="25px"
                      src="https://i.postimg.cc/J7X80y3t/email.png"
                      alt=""
                    />
                    <span className="footer-text-white ml-2">
                      {WebSitesettings.CompanyEmail}
                    </span>
                  </button>
                </a>
              </div>
 
</div></div>
          <div className="col-12 my-2 text-center font-weight-bold">
            <a className="cxy flex-column" href="#">
              {/*<span className="footer-text-bold">
                <a
                  href={
                    WebSitesettings.CompanyMobile
                      ? "tel:" + WebSitesettings.CompanyMobile
                      : ""
                  }
                >
                  {WebSitesettings.CompanyMobile
                    ? WebSitesettings.CompanyMobile
                    : ""}
                </a>
              </span>
              <span className="footer-text-bold">
                {WebSitesettings ? WebSitesettings.CompanyName : ""}
              </span>*/}
              <span className="footer-text-bold">
                {WebSitesettings ? WebSitesettings.CompanyAddress : ""}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default Support;
